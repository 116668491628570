<template>
  <el-dialog
    title="服务记录"
    :visible.sync="showMsg"
    width="90%"
    height="80vh"
    top="5vh"
    :show-close="true"
    :close-on-click-modal="true"
    :close-on-press-escape="false"
    :before-close="handleClose"
  >
    <el-table
      v-loading="dataListLoading"
      :data="dataList"
      border
      @selection-change="dataListSelectionChangeHandle"
      style="width: 100%"
    >
      <el-table-column
        prop="msgSeq"
        label="序号"
        header-align="center"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="msgTime"
        label="时间"
        header-align="center"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="fromAccount"
        label="发言人"
        header-align="center"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="text"
        label="信息内容"
        header-align="center"
        align="center"
      >
        <template slot-scope="scope">{{ selectMsg(scope.row) }}</template>
      </el-table-column>
      <el-table-column
        prop="intervalTime"
        label="时长"
        header-align="center"
        align="center"
      ></el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import mixinViewModule from '@/mixins/view-module';
const apiUrls = {
  flow: '/trtc/msg/list',
};
export default {
  mixins: [mixinViewModule],
  props: {
    uuid: {
      type: String,
      required: true,
    },
    showMsg: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/trtc/msg/list',
        getDataListIsPage: false,
        // deleteURL: '/sys/smslog',
        deleteIsBatch: true,
      },
      dataForm: {
        uuid: this.uuid,
      },
    };
  },
  watch: {
    async uuid(val, oldVal) {
      if (val && val !== oldVal && this.showMsg) {
        this.dataForm.uuid = val;
        this.getDataList();
      }
    },
  },
  methods: {
    selectMsg({ msgType, text, imageUrl, cloudCustomData }) {
      //             TIMTextElem 取Text
      // TIMImageElem 取ImageUrl
      // TIMCustomElem 取CloudCustomData
      switch (msgType) {
        case 'TIMTextElem':
          return text;
        case 'TIMImageElem':
          return imageUrl;

        default:
          return cloudCustomData;
      }
    },
    handleClose() {
      this.$emit('changeMsgState');
    },
  },
};
</script>

<style></style>
