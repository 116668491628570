<template>
    <el-card shadow="never" class="aui-card--fill">
        <div class="mod-demo__syssmslog">
            <el-form
                :inline="true"
                :model="dataForm"
                @keyup.enter.native="getDataList()"
            >
                <el-form-item label="问诊时间">
                    <el-date-picker
                        v-model="dataForm.startDate"
                        value-format="yyyy-MM-dd"
                        type="date"
                        placeholder="问诊开始时间"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="至">
                    <el-date-picker
                        v-model="dataForm.endDate"
                        value-format="yyyy-MM-dd"
                        type="date"
                        placeholder="问诊结束时间"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="渠道">
                    <el-select v-model="dataForm.appCode" placeholder="渠道">
                        <el-option
                            v-for="item in codeList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="问诊类型">
                    <el-select
                        v-model="dataForm.inquiryType"
                        placeholder="问诊类型"
                        clearable
                    >
                        <el-option label="视频" value="V"></el-option>
                        <el-option
                            label="图文"
                            value="I"
                        ></el-option> </el-select
                ></el-form-item>
                <el-form-item label="医生名">
                    <el-input
                        v-model="dataForm.doctorName"
                        placeholder="医生名"
                        clearable
                /></el-form-item>
                <el-form-item label="患者名">
                    <el-input
                        v-model="dataForm.name"
                        placeholder="患者名"
                        clearable
                /></el-form-item>
                <el-form-item label="问诊单号">
                    <el-input
                        v-model="dataForm.inquiryNo"
                        placeholder="问诊单号"
                        clearable
                /></el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="getDataList()"
                        >查询</el-button
                    >
                </el-form-item>
                <el-form-item>
                    <el-button @click="resetSearch()">{{
                        $t('cardList.reset')
                    }}</el-button>
                </el-form-item>
            </el-form>
            <el-table
                v-loading="dataListLoading"
                :data="dataList"
                border
                @selection-change="dataListSelectionChangeHandle"
                style="width: 100%"
            >
                <el-table-column
                    prop="name"
                    label="患者姓名"
                    header-align="center"
                    align="center"
                >
                    <template
                        slot-scope="scope"
                        @click="copyText(scope.row.uuid)"
                    >
                        <el-button
                            type="text"
                            size="small"
                            id="btn"
                            @click="copyText(scope.row.uuid)"
                            >{{ scope.row.name || '无名' }}</el-button
                        >
                    </template>
                </el-table-column>

                <el-table-column
                    prop="appDesc"
                    label="渠道"
                    header-align="center"
                    align="center"
                ></el-table-column>

                <el-table-column
                    prop="inquiryType"
                    label="问诊类型"
                    header-align="center"
                    align="center"
                    ><template slot-scope="scope">{{
                        typename(scope.row.inquiryType)
                    }}</template></el-table-column
                >

                <el-table-column
                    label="问诊单号"
                    prop="inquiryNo"
                    header-align="center"
                    align="center"
                >
                    <!-- <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            id="btn"
                            v-if="scope.row.inquiryNo"
                            @click="copyText(scope.row.uuid)"
                            >{{ scope.row.inquiryNo }}</el-button
                        >

                    </template> -->
                </el-table-column>

                <el-table-column
                    prop="inTime"
                    label="首次进线时间"
                    header-align="center"
                    align="center"
                ></el-table-column>

                <el-table-column
                    prop="answerDate"
                    label="应答时间"
                    header-align="center"
                    align="center"
                ></el-table-column>

                <el-table-column
                    prop="hangUpDate"
                    label="挂断时间"
                    header-align="center"
                    align="center"
                ></el-table-column>

                <el-table-column
                    label="服务时长"
                    header-align="center"
                    align="center"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            id="btn"
                            v-if="scope.row.serverTime"
                            @click="flowSelectId(scope.row.uuid)"
                            >{{ scope.row.serverTime }}</el-button
                        >
                        <!-- @click="deleteHandle(scope.row.id)" -->
                    </template>
                </el-table-column>

                <el-table-column
                    prop="doctorName"
                    label="应答医生"
                    header-align="center"
                    align="center"
                ></el-table-column>

                <el-table-column
                    label="问诊记录"
                    header-align="center"
                    align="center"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="msgSelectId(scope.row.uuid)"
                            >查看记录</el-button
                        >
                        <!-- @click="deleteHandle(scope.row.id)" -->
                    </template>
                </el-table-column>

                <el-table-column
                    label="查看药方"
                    header-align="center"
                    align="center"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            v-if="scope.row.rimg"
                            >查看药方
                            <img
                                v-image-preview
                                :src="scope.row.rimg"
                                width="100%"
                                height="100%"
                                style="
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    opacity: 0;
                                "
                        /></el-button>

                        <div v-else>无药方</div>
                    </template>
                </el-table-column>

                <!-- <el-table-column
                    :label="$t('handle')"
                    fixed="right"
                    header-align="center"
                    align="center"
                    width="150"
                >
                    <template slot-scope="scope">
                        <el-button
                            v-if="$hasPermission('sys:smslog:all')"
                            type="text"
                            size="small"
                            @click="deleteHandle(scope.row.id)"
                            >{{ $t('delete') }}</el-button
                        >
                    </template>
                </el-table-column> -->
            </el-table>
            <el-pagination
                :current-page="page"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="limit"
                :total="total"
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="pageSizeChangeHandle"
                @current-change="pageCurrentChangeHandle"
            >
            </el-pagination>
            <VideoLogFlow
                :uuid="this.uuid"
                :showFlow="showFlow"
                @changeFlowState="changeFlowState"
            />
            <VideoMsgFlow
                :uuid="this.uuid"
                :showMsg="showMsg"
                @changeMsgState="changeMsgState"
            />
        </div>
    </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module';
import ClipboardJS from 'clipboard';
import VideoLogFlow from './videoLogFlow.vue';
import VideoMsgFlow from './videoMsgFlow.vue';
const urls = {
    getCode: '/sysapp/list', // 获取应用code
};

export default {
    mixins: [mixinViewModule],
    components: {
        VideoLogFlow,
        VideoMsgFlow,
    },
    data() {
        return {
            mixinViewModuleOptions: {
                getDataListURL: '/trtc/page',
                getDataListIsPage: true,
                // deleteURL: '/sys/smslog',
                deleteIsBatch: true,
            },
            dataForm: {
                inquiryType: '',
                startDate: '', // 时间范围
                endDate: '', // 时间范围
                doctorName: '', // 医生名
                name: '', // 患者名
                appCode: '', // 业务渠道
                inquiryNo: '',
            },
            codeList: [], // 业务渠道
            uuid: '', // 选中的uuid
            showFlow: false,
            showMsg: false,
        };
    },
    mounted() {
        this.getCodeList();
    },
    methods: {
        changeFlowState() {
            this.showFlow = false;
        },
        changeMsgState() {
            this.showMsg = false;
        },
        flowSelectId(id) {
            this.uuid = id;
            this.showFlow = true;
        },
        msgSelectId(id) {
            this.uuid = id;
            this.showMsg = true;
        },
        copyText(str) {
            const clipboard = new ClipboardJS(`#btn`, {
                text: (trigger) => {
                    const codeStr = str;
                    this.$notify({
                        title: '成功',
                        message: 'uuid已复制到剪切板，可粘贴。',
                        type: 'success',
                    });
                    clipboard.destroy();
                    return codeStr;
                },
            });
            clipboard.on('error', (e) => {
                this.$message.error('uuid复制失败');
                clipboard.destroy();
            });
        },
        resetSearch() {
            this.dataForm = {
                inquiryType: '',
                startDate: '', // 时间范围
                endDate: '', // 时间范围
                doctorName: '', // 医生名
                name: '', // 患者名
                appCode: '', // 业务渠道
            };
            this.getDataList();
        },
        typename(inquiryType) {
            return inquiryType === 'V' ? '视频' : '图文';
        },
        async getCodeList() {
            const res = await this.$http.get(urls.getCode);

            const resData = res.data.data || [];
            this.codeList = resData.map((item) => {
                return {
                    label: item.appDesc,
                    value: item.appCode,
                };
            });
            console.log(this.limitList, 9099);
        },
    },
};
</script>
